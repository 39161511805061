<template>
  <div class="particulars">
    <Ytx_header />
    <div class="tit_box">产品展示</div>
    <div class="particulars-center">
      <div class="product">
        <div class="product-content">
          <div class="store-content">
            <div class="store-list">
              <div
                class="container_row_spec"
                v-for="(item, i) in productlist"
                :key="i"
              >
                <router-link
                  target="_blank"
                  :to="
                    '/product/detail?uniqueNo=' +
                      item.uniqueNo +
                      '&fromoa=' +
                      (isfromoa ? '1' : '')
                  "
                >
                  <div class="item-img">
                    <el-image :src="item.headImg" fit="cover">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div class="product-title">
                      {{ item.title }}
                    </div>
                    <div class="product-price">
                      <template v-if="item.billingMode != ''">
                        <span class="rmb"> </span>
                        {{ item.billingVal }}
                      </template>
                      <template v-else>
                        <span class="rmb">¥</span>{{ item.price }}
                      </template>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="page">
            <el-pagination
              @size-change="handlecustomSizeChange"
              background
              @current-change="handlecustomCurrentChange"
              :current-page="pageIndex"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Ytx_footer />
  </div>
</template>
<script>
import { companygetcompanyinfo } from '@/api/company.js';
import { productgetcompanyproductlist } from '@/api/product';

import Ytx_header from '@/components/ytx_header.vue';
import Ytx_footer from '@/components/ytx_footer.vue';

export default {
  components: {
    Ytx_header,
    Ytx_footer
  },
  data() {
    return {
      isfromoa: false,
      comCertList: [],
      comHonorList: [],
      comElegantList: [],
      productlist: [],
      a: 1,
      activeName: 'first',
      productactiveName: 'first',
      cid: '',
      isloding: false,
      info: {},
      cmpinfo: {},
      isskip: 1,
      OfficeVisible: false,
      tableData: [],
      isInquriy: 1,
      type: '',
      pageIndex: 1,
      pageSize: 12,
      totalCount: 0,
      custompageIndex: 1,
      custompageSize: 12,
      customtotalCount: 0,
      comTeamHonorList: []
    };
  },
  methods: {
    handlecustomCurrentChange(item) {
      this.pageIndex = item;
      this.onproductgetcompanyproductlist();
    },
    handlecustomSizeChange(item) {
      this.pageSize = item;
      this.onproductgetcompanyproductlist();
    },
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then(res => {
        console.log(res);
        this.cmpinfo = res.data.result;
        for (let i = 0; i < res.data.result.comHonorList.length; i++) {
          this.comHonorList.push(res.data.result.comHonorList[i].imgOUrl);
        }
        for (let i = 0; i < res.data.result.comElegantList.length; i++) {
          this.comElegantList.push(res.data.result.comElegantList[i].imgOUrl);
        }
        for (let i = 0; i < res.data.result.comCertList.length; i++) {
          this.comCertList.push(res.data.result.comCertList[i].imgOUrl);
        }

        this.comTeamHonorList = this.cmpinfo.comTeamHonorList;
      });
    },
    onproductgetcompanyproductlist() {
      var that = this;
      productgetcompanyproductlist({
        companyid: this.cid,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.data.code == 1) {
          that.productlist = res.data.result.list;
          console.log(that.productlist);
          that.totalCount = res.data.result.totalCount;
        }
      });
    }
  },
  mounted() {
    var id = this.$route.query.id;
    this.isfromoa = this.$route.query.fromoa == '1';
    this.cid = id;
    this.isInquriy = this.$route.query.isInquriy;
    this.info = {
      cmp: {},
      cert: {}
    };
    this.isloding = true;
    this.oncompanygetcompanyinfo();
    this.onproductgetcompanyproductlist();
  }
};
</script>
<style scoped>
.container_row_spec .proimg {
  width: 160px;
  height: 160px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.container_row_spec .proimg >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}
.particulars {
  width: 100%;
  background: #fff;
}

.particulars-center {
  width: 100%;
  min-width: 1280px;
}

.product {
  width: 100%;
  background: #fff;
}
.product .product-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
.product .store-content {
  width: 100%;
  display: flex;
  margin: 80px 0;
}
.store-list {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.container_row {
  margin: 0px auto;
}
.container_row_spec {
  width: 220px;
  height: 263px;
  background-color: #fff;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}

.container_row_spec img.container_row_img {
  width: 100%;
  height: 220px;
}

.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}
.container_row_img {
  cursor: pointer;
}
/* .container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
} */
.container_row_name {
  color: #666;
  height: 35px;
  font-size: 12px;
  width: 100%; /* margin-top: -4px; */
  background-color: #fff;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 40px;
}
.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_price {
  background-color: #fff;
  font-size: 20px;
  padding: 0 10px;
  color: #b31e22;
}
.container_row_price .rmb {
  font-size: 12px;
  color: #666;
}
.container_row_spec.custom {
  height: 200px;
  width: 180px;
}
.container_row_price .mode {
  font-size: 12px;
  color: #666;
}

.container_row_map {
  width: 100%;
  margin-top: -31px;
  height: 28px;
  background-color: #1c1c1c;
  opacity: 0.76;
  bottom: 0;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  padding-left: 9px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
}

.container_row_spec .item-img {
  width: 170px;
  height: 170px;
  text-align: center;
  margin: 18px 5px;
}
.container_row_spec .item-img .el-image {
  width: 160px;
  height: 160px;
  border-radius: 10%;
  border: 1px solid #ededed;
}
.container_row_spec .item-img >>> .image-slot {
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ededed;
}

.container_row_spec .company-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container_row_spec .product-title {
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center; */
}
.container_row_spec .product-price {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ff1100;
}
.clearfix {
  clear: both;
}
.page {
  margin-top: 30px;
  margin-bottom: 60px;
  text-align: center;
}

.tit_box {
  height: 44px;
  line-height: 44px;
  background: url(~@/assets/ytx_img/barbg.png) top center no-repeat;
  text-align: center;
  font-size: 26px;
  color: #333;
  font-weight: bold;
  width: 1280px;
  margin: 0 auto;
  margin-top: 80px;
}
</style>
